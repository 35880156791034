<template>
  <div>
    <b-row class="mb-1" align-h="end">
      <b-col md="2">
        <b-button
          @click="downloadSampleExcel"
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          pill
          size="sm"
          v-if="$can('create', 'budget_expense')"
        >
          Sample Excel
        </b-button>
      </b-col>
    </b-row>
    <b-card v-if="$can('create', 'budget_expense')">
      <b-form>
        <b-row class="mb-1">
          <b-col md="4">
            <v-select
              v-model="form.movie"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="movie_list"
              label="full_title"
              value="movie_id"
              placeholder="Select Movie"
              class="mb-1"
              disabled
            />
          </b-col>
          <b-col md="4">
            <b-form-file
              class="mb-1"
              id="file"
              accept=".xlsx, .xls"
              v-model="form.uploaded_file"
            />
          </b-col>
          <b-col md="3">
            <b-button
              class="mb-1"
              @click="uploadExpense"
              variant="relief-primary"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              >Upload Expense</b-button
            >
          </b-col>
        </b-row>
      </b-form>
      <hr />

      <div class="d-flex mb-2">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">Manual Input</h4>
      </div>

      <b-form class="mb-2">
        <div
          v-for="(manual_input, index) in form.manual_inputs"
          :key="'manual_input' + index"
        >
          <b-row>
            <b-col md="3">
              <b-form-group label="Vendor">
                <v-select
                  v-model="form.manual_inputs[index].vendor"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="vendor_list"
                  label="company"
                  value="vendor_id"
                  placeholder="Select Vendor"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Invoice Number">
                <b-form-input
                  v-model="form.manual_inputs[index].invoice_number"
                  class="form-control"
                  size="sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Category">
                <v-select
                  v-model="form.manual_inputs[index].category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="category_list"
                  @input="
                    (val) =>
                      getBudgetSubCategoriesByParent(index, val.category_id)
                  "
                  label="category_name"
                  value="category_id"
                  placeholder="Please Select"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Sub-Category">
                <v-select
                  v-model="form.manual_inputs[index].sub_category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="form.manual_inputs[index].sub_category_list"
                  @input="
                    (val) =>
                      getBudgetTerritoryBySubCategory(
                        index,
                        val.sub_category_id
                      )
                  "
                  label="sub_category_name"
                  value="sub_category_id"
                  placeholder="Please Select"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Territory">
                <v-select
                  v-model="form.manual_inputs[index].territory"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="form.manual_inputs[index].territory_list"
                  label="territory_name"
                  value="territory_id"
                  placeholder="Please Select"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Unit">
                <b-form-input
                  v-model="form.manual_inputs[index].unit"
                  class="form-control"
                  size="sm"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Rate">
                <b-form-input
                  v-model="form.manual_inputs[index].rate"
                  class="form-control"
                  size="sm"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Amount">
                <b-form-input
                  v-model="form.manual_inputs[index].amount"
                  class="form-control"
                  size="sm"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Invoice Date">
                <flat-pickr
                  v-model="form.manual_inputs[index].invoice_date"
                  class="form-control control-sm"
                  :config="{
                    ...$flatPickrConfig,
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Attachment">
                <b-form-file
                  class="form-control"
                  size="sm"
                  v-model="form.manual_inputs[index].uploaded_attachment"
                  @input="
                    () => {
                      uploadAttachment(index);
                    }
                  "
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Explanation">
                <b-form-textarea
                  v-model="form.manual_inputs[index].explanation"
                  class="form-control"
                  size="sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
        </div>
        <b-button
          block
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          pill
          @click="
            () => {
              this.form.manual_inputs = [
                ...this.form.manual_inputs,
                this.manual_input_object,
              ];
            }
          "
          >Add More</b-button
        >
      </b-form>

      <b-row align-h="end">
        <b-button
          @click="addExpense"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          class="mr-1"
          variant="primary"
        >
          Save
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          @click="resetManualInputs"
        >
          Reset
        </b-button>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

import { getAllMovie } from "@/apiServices/MovieServices";
import { getAllData, uploadFiles } from "@/apiServices/MastersServices";
import {
  GetBudgetMainInfo,
  GetBudgetCategories,
  GetBudgetSubCategoriesByParent,
  GetBudgetTerritoryBySubCategory,
  AddExpense,
} from "@/apiServices/BudgetExpenseServices";
import { getAllVendor } from "@/apiServices/VendorServices";
import axios from "axios";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormFile,
    vSelect,
    ToastificationContentVue,
    BFormTextarea,
    flatPickr,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      movie_list: [],
      category_list: [],
      vendor_list: [],
      currency_list: [],
      budget_main_info: null,
      manual_input_object: {
        amount: null,
        attachment: null,
        category: null,
        explanation: null,
        invoice_date: null,
        invoice_number: null,
        rate: null,
        sub_category: null,
        sub_category_list: [],
        territory: null,
        territory_list: [],
        unit: null,
        uploaded_attachment: null,
        vendor: null,
      },
      form: {
        budget_main_id: null,
        movie: {},
        uploaded_file: null,
        manual_inputs: [{ sub_category_list: [], territory_list: [] }],
      },
    };
  },

  methods: {
    downloadSampleExcel() {
      fetch(this.BASE_URL + "/budgetExpense/downloadSampleBudgetExpense", {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var _url = window.URL.createObjectURL(blob);
          window.open(_url, "_blank").focus();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getVendors() {
      try {
        const response = await getAllVendor();
        if (response.status == 200) {
          this.vendor_list = response.data.data.data;
        }
      } catch (err) {
        console.log("Error => ", err.message);
      }
    },

    async getMovies() {
      const response = await getAllMovie();
      if (response.status == 200) {
        this.movie_list = response.data.data.data;
      }
    },

    async uploadExpense() {
      if (!this.form.movie || !this.form.movie.movie_id) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Missing movie info!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      if (!this.form.uploaded_file) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please upload the Excel file!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      const url = this.BASE_URL + "/budgetExpense/uploadExpenseExcel";
      const formData = new FormData();
      formData.append("file", this.form.uploaded_file);
      formData.append("movie_id", this.form.movie.movie_id);
      formData.append("budget_main_id", this.form.budget_main_id);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await axios.post(url, formData, config).then((response) => {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Salman add the default message",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      });
    },

    async getBudgetMainInfo() {
      const response = await GetBudgetMainInfo(this.form.budget_main_id);
      if (response.data.status) {
        this.budget_main_info = response.data.data;
        this.form.movie.movie_id = this.budget_main_info.movie_id;
        this.form.movie.title = this.budget_main_info.title;
      }
    },

    async getBudgetCategories() {
      const response = await GetBudgetCategories(this.form.budget_main_id);
      if (response.data.status) {
        this.category_list = response.data.data;
      }
    },

    async getBudgetSubCategoriesByParent(index, category_id) {
      const response = await GetBudgetSubCategoriesByParent(
        this.form.budget_main_id,
        category_id
      );
      if (response.data.status) {
        this.form.manual_inputs[index].sub_category_list = response.data.data;
      }
    },

    async getBudgetTerritoryBySubCategory(index, sub_category_id) {
      const response = await GetBudgetTerritoryBySubCategory(
        this.form.budget_main_id,
        sub_category_id
      );
      if (response.data.status) {
        this.form.manual_inputs[index].territory_list = response.data.data;
      }
    },

    async uploadAttachment(index) {
      if (!this.form.manual_inputs[index].uploaded_attachment) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please upload the file!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      const url = this.BASE_URL + "/budget/uploadExcel";
      const formData = new FormData();
      formData.append(
        "files",
        this.form.manual_inputs[index]?.uploaded_attachment
      );

      const response = await uploadFiles(formData);
      if (!response.data.status) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title:
              response.data.message || response.data.error || "Upload failed!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
      this.form.manual_inputs[index].attachment = response.data.data.names[0];
    },

    async addExpense() {
      const payload = this.form.manual_inputs.map((item) => ({
        budget_main_id: this.form.budget_main_id,
        budget_id: item.category.budget_id,
        movie_id: this.form.movie.movie_id,
        vendor_id: item.vendor.vendor_id,
        invoice_number: item.invoice_number,
        category_id: item.category.category_id,
        sub_category_id: item.sub_category.sub_category_id,
        territory_id: item.territory.territory_id,
        unit: item.unit,
        rate: item.rate,
        amount: item.amount,
        invoice_date: item.invoice_date,
        attachment: item.attachment,
        explanation: item.explanation,
      }));
      console.log("payload => ", payload);
      const response = await AddExpense(payload);
      if (!response.data.status) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title:
              response.data.message || response.data.error || "Upload failed!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Success",
          icon: "EditIcon",
          variant: "success",
        },
      });
      this.resetManualInputs();
    },

    resetManualInputs() {
      this.form.manual_inputs = [this.manual_input_object];
    },
  },

  beforeMount() {
    this.form.budget_main_id = this.$route.params.id;
    this.getBudgetMainInfo();
    this.getMovies();
    this.getVendors();
    this.getBudgetCategories();
  },
};
</script>

<style lang="scss">
.control-sm {
  height: 2.142rem;
  font-size: 0.857rem;
  line-height: 1;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
