
import Api from './Api'

export const GetBudgetMainInfo = async (id) => Api().get(`budgetExpense/budgetMainInfo/${id}`)

export const GetBudgetCategories = async (id) => Api().get(`budgetExpense/getBudgetCategories/${id}`)

export const GetBudgetSubCategoriesByParent = async (id, category_id) => Api().get(`budgetExpense/getBudgetSubCategoriesByParent/${id}/${category_id}`)

export const GetBudgetTerritoryBySubCategory = async (id, sub_category_id) => Api().get(`budgetExpense/getBudgetTerritoryBySubCategory/${id}/${sub_category_id}`)

export const AddExpense = async (payload) => Api().post("budgetExpense/addExpense", payload)

export const GetExpenseByMainID = async (id) => Api().get(`budgetExpense/getExpenseByMainID/${id}`)